<template>
    <b-taglist v-if="env != 'prod'" attached class="environment">
        <b-tag type="is-danger">{{ env }}</b-tag>
    </b-taglist>
</template>

<script>
export default {
	name: 'Environment',
	data() {
		return {
			env: 'prod'
		}
	},
	mounted() {
        let host = window.location.hostname
        this.env =
            host == 'localhost' ? 'dev' :
            host == 'vep.station.orbital.company' || host  ==  'stage.dealerexcellenceprogram.com' ? 'stage' : 'prod'
    }
}
</script>
